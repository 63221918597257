import React from "react"
import { graphql } from "gatsby"
import RoomTemplate from "../../templates/room-template"
import Seo from "../../components/SEO"
import Layout from "../../components/Layout"

const Room = ({ data: { contentfulRoom } }) => {
  return (
    <>
      <Seo title={contentfulRoom.title} />
      <Layout>
        <RoomTemplate {...contentfulRoom} />
      </Layout>
    </>
  )
}

export const data = graphql`
  query roomQuery($id: String) {
    contentfulRoom(id: { eq: $id }) {
      title
      description
      headerImage {
        gatsbyImageData(
          width: 2000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
      gallery {
        thumb: gatsbyImageData(
          width: 700
          height: 500
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
        full: gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

export default Room
