import React from "react"
import Seo from "../components/SEO"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import BannerModule from "../components/BannerModule/BannerModule"
import Features from "../components/Features/Features"
import Gallery from "@browniebroke/gatsby-image-gallery"

const RoomTemplateStyles = styled.div`
  .container {
    display: flex;
    flex-wrap: wrap;
    .column {
      flex: 0 0 100%;

      @media (min-width: 768px) {
        flex-basis: 50%;

        &:nth-child(1) {
          padding-right: 20px;
        }

        &:nth-child(2) {
          padding-left: 20px;
        }

        > * {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      > * {
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
`

const RoomGallery = styled.section`
  width: 100%;

  > .container {
    display: flex;
    flex-wrap: wrap;
    gap: calc(var(--gap) / 2);

    @media (min-width: 1024px) {
      gap: var(--gap);
    }

    > * {
      width: calc(50% - 10px);

      @media (min-width: 768px) {
        width: calc(33.333% - 14px);
      }

      @media (min-width: 1024px) {
        width: calc(25% - 30px);
      }
    }
  }
`

const Roomtemplate = (contentfulRoom) => {
  const { headerImage, title, description, gallery } = contentfulRoom
  const roomHeaderImage = getImage(headerImage)
  return (
    <>
      <Seo title={title} />
      <BannerModule title={title} subTitle={description} enquire={false}>
        <GatsbyImage
          className="banner__image"
          image={roomHeaderImage}
          alt={title}
        />
      </BannerModule>
      {/* <RoomTemplateStyles className="section">
        <div className="container container__tight">
          {description && (
            <div className="column">
              <RichText richText={description} />
            </div>
          )}
        </div>
      </RoomTemplateStyles> */}
      {gallery && (
        <RoomGallery className="section">
          <Gallery
            colWidth="50"
            mdColWidth="33"
            className="container container__tight"
            images={gallery}
          />
        </RoomGallery>
      )}
      <Features
        title="Featured Rooms from Eclipse"
        introduction="Check out our featured rooms to find the accommodation that best suits your needs"
      />
    </>
  )
}

export default Roomtemplate
